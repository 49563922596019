<script setup lang="ts">
import IconCancel from '~icons/ph/x';
import IconSearch from '~icons/system-uicons/capture';
import IconDocument from '~icons/humbleicons/document';
import IconReload from '~icons/tabler/reload';
import IconLoading from '~icons/eos-icons/loading';
import axios from 'axios';
import IconLeft from '~icons/formkit/left';
import IconRight from '~icons/formkit/right';
import qs from 'qs';

const apiBaseUrl = import.meta.env.VITE_APIURL;

interface ImageProps {
  imgurl?: string;
  imgid: string;
  openDirection?: string;
  hasPrev?: boolean;
  hasNext?: boolean;
}

const props = defineProps<ImageProps>();

const emit = defineEmits(['close', 'prev', 'next']);

const errorMsg = ref('');
const errorOpen = ref(false);
const rec_img_data: any = ref(null);
const zoomClicked = ref(false);

const openDirection = computed(() => {
  if (props.openDirection && ['fromLeft', 'fromRight', 'fromBottom'].includes(props.openDirection)) {
    return props.openDirection;
  }
  return 'fromBottom';
});

const closeError = () => {
  errorOpen.value = false;
};

const zoomTransformation = computed(() => {
  return getTransformation();
});

const getTransformation = () => {
  const resultObject = rec_img_data.value.recognition_result;

  if (!resultObject || !resultObject.label || !resultObject.label.image_dimensions || !resultObject.label.position) {
    return '';
  }

  const imagewidth = resultObject.label.image_dimensions.width;
  const imageheight = resultObject.label.image_dimensions.height;
  const labelleft = resultObject.label.position.left;
  const labelright = resultObject.label.position.right;
  const labeltop = resultObject.label.position.top;
  const labelbottom = resultObject.label.position.bottom;

  const labelwidth = labelright - labelleft;
  const labelheight = labelbottom - labeltop;
  const labelcenter_x = labelleft + labelwidth / 2;
  const labelcenter_y = labeltop + labelheight / 2;

  const scale_x = imagewidth / labelwidth;
  const scale_y = imageheight / labelheight;
  const scale = Math.min(scale_x, scale_y);

  const translate_x = (50 - (labelcenter_x / imagewidth) * 100) * scale;
  const translate_y = (50 - (labelcenter_y / imageheight) * 100) * scale;

  return {
    transform: `translate(${translate_x}%, ${translate_y}%) scale(${scale})`,
  };
}

const recognitionStatus = computed(() => {
  if (rec_img_data.value?.recognition_status == 'success' && rec_img_data.value?.is_basis_for) {
    return 'bestmatch';
  } else {
    return rec_img_data.value?.recognition_status;
  }
})

const panelTypename = computed(() => {
  if (rec_img_data?.value?.recognition_status != 'success') {
    return null;
  }

  const typename = rec_img_data?.value?.recognition_result?.properties?.filter((prop: any) => prop.name === 'typename')?.[0]?.candidates?.toSorted((a: any, b: any) => a.score - b.score)?.[0]?.guess;

  return typename;
});

const closePopup = () => {
  emit('close');
};

const loadRecognitionResult = async () => {
  const requestParams = {
    params: {
      populate: {
        is_basis_for: true,
      },
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params);
    },
  };


  try {
    const res = await axios.get(apiBaseUrl + 'recognition-images/' + props.imgid, requestParams);
    rec_img_data.value = res?.data?.data || null;
  } catch (error: any) {
    errorMsg.value = error;
    errorOpen.value = true;
  }
};

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    event.preventDefault();
    closePopup();
  }
  if (event.key === 'ArrowLeft' && props.hasPrev) {
    event.preventDefault();
    emit('prev');
  }
  if (event.key === 'ArrowRight' && props.hasNext) {
    event.preventDefault();
    emit('next');
  }
  if (event.key === 'z') {
    event.preventDefault();
    zoomClicked.value = !zoomClicked.value;
  }
};

const dialogWrapper = ref<HTMLElement | null>(null);

onMounted(async () => {
  if (dialogWrapper.value) {
    dialogWrapper.value.focus();
  }
  await loadRecognitionResult();
});


</script>
<template>
  <Teleport to="body">
    <div>
      <Transition appear leave-from-class="opacity-100" leave-to-class="opacity-0"
        :enter-from-class="openDirection == 'fromBottom' ? 'opacity-0' : 'opacity-1'" enter-to-class="opacity-100"
        leave-active-class="transition duration-500 ease-in" enter-active-class="transition duration-500 ease-out">
        <div class="fixed inset-0 z-40">
          <div class="absolute inset-0 z-20 bg-black bg-opacity-50" @click="closePopup()"></div>
          <a v-if="props.hasPrev"
            class="fixed z-30 left-0 inset-y-0 h-24 w-24 my-auto ml-2 rounded-full hover:bg-black hover:bg-opacity-25 cursor-pointer"
            @click="emit('prev')">
            <IconLeft class="w-20 h-20 text-white ml-1 mr-3 my-2" aria-hidden="true" />
          </a>
          <a v-if="props.hasNext"
            class="fixed z-30 right-0 inset-y-0 h-24 w-24 my-auto mr-2 rounded-full hover:bg-black hover:bg-opacity-25 cursor-pointer"
            @click="emit('next')">
            <IconRight class="w-20 h-20 text-white my-2 ml-3 mr-1" aria-hidden="true" />
          </a>
        </div>
      </Transition>
      <Transition appear leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-4"
        :enter-from-class="openDirection == 'fromLeft' ? 'opacity-0 -translate-x-4' :
          openDirection == 'fromRight' ? 'opacity-0 translate-x-4' :
            'opacity-0 translate-y-4'" enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition duration-500 ease-in" enter-active-class="transition duration-500 ease-out">
        <div ref="dialogWrapper"
          class="fixed my-8 mx-auto max-w-7xl inset-0 z-50 pointer-events-none focus:outline-none"
          @keydown="handleKeydown" tabindex="0">
          <div class="absolute inset-y-0 bg-white rounded overflow-y-auto pointer-events-auto mx-8 w-[calc(100%-4rem)]">
            <a class="absolute right-0 top-0 m-8 z-10 cursor-pointer" @click.prevent="closePopup()">
              <IconCancel class="w-16 h-16 text-white drop-shadow-2xl" aria-hidden="true" />
            </a>
            <div class="w-full overflow-hidden relative">
              <div class="blur-lg bg-black">
                <img :src="props.imgurl"
                  class="w-full block transition-transform duration-300 translate-x-0 translate-y-0 bg-black max-h-[calc(100vh-4rem)] scale-125" />
              </div>
              <div class="w-full flex items-center justify-center">
                <img :src="props.imgurl"
                  class="flex scale-100 transition-transform duration-300 translate-x-0 translate-y-0 bg-transparent max-h-[calc(100vh-4rem)] absolute top-0"
                  :style="zoomClicked ? zoomTransformation : ''" @dblclick="zoomClicked = !zoomClicked" />
              </div>
              <!-- transform image after click -->
              <a v-if="rec_img_data?.recognition_status == 'success'" href=""
                class="absolute block right-0 bottom-0 m-8 group cursor-pointer"
                @click.prevent="zoomClicked = !zoomClicked">
                <IconSearch
                  class="w-16 h-16 text-white drop-shadow-2xl scale-100 group-hover:scale-95 transition-transform duration-150"
                  aria-hidden="true" />
                <IconDocument class="w-8 h-8 text-white drop-shadow-2xl absolute left-4 top-4" aria-hidden="true" />
              </a>
              <div v-if="panelTypename"
                class="absolute top-0 left-0 m-8 rounded h-8 leading-6 px-3 py-1 text-center font-normal overflow-hidden bg-white cursor-default hover:opacity-10">
                {{ panelTypename }}
              </div>
            </div>
            <div v-if="rec_img_data !== null" class="p-8">
              <div class="flex flex-wrap items-center justify-between content-start gap-x-6 gap-y-3 mb-8">
                <div class="shrink-0 basis-52">
                  <StateItem :state="recognitionStatus"></StateItem>
                </div>
              </div>
              <div class="mb-10 last:mb-0">
                <h3 class="text-base leading-6 font-medium text-slate-900 relative">Result</h3>
                <pre class="input-text mb-8">{{ rec_img_data.recognition_result }}</pre>
              </div>
              <div class="flex flex-wrap justify-start items-start content-start gap-x-6 gap-y-3 mb-10 last:mb-0">
                <div class="grow shrink-0 basis-52">
                  <h3 class="text-base leading-6 font-medium text-slate-900 relative">Image Type</h3>
                  <pre class="input-text">{{ rec_img_data.image_type }}</pre>
                </div>
                <div class="grow shrink-0 basis-52">
                  <h3 class="text-base leading-6 font-medium text-slate-900 relative">Image ID:</h3>
                  <pre class="input-text">{{ rec_img_data.documentId }}</pre>
                </div>
              </div>
              <div class="mb-10 last:mb-0">
                <h3 class="text-base leading-6 font-medium text-slate-900 relative">Date</h3>
                <div class="flex flex-wrap justify-start items-start content-start gap-x-6 gap-y-3 mt-1">
                  <div class="grow shrink-0 basis-52">
                    <label class="label">Created</label>
                    <input type="text" class="input-text" :value="rec_img_data.createdAt" disabled>
                  </div>
                  <div class="grow shrink-0 basis-52">
                    <label class="label">Updated</label>
                    <input type="text" class="input-text" :value="rec_img_data.updatedAt" disabled>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
    <DialogPopup :open="errorOpen" @close="closeError()" :appearance="'error'" :title="'Error'">
      {{ errorMsg }}
    </DialogPopup>
  </Teleport>
</template>