import TableImages from '@/components/common/table/TableImages.vue';
import { defineComponent } from 'vue';

export default class TableImagesService {
  public static customImages = (row: any, field: string) => {
    if (row?.recognition_images === undefined) {
      return;
    }
    let arr = row.recognition_images;
    let typename = row?.panelmodel?.typename;
    let urls: Array<any> = arr.reduce((result: Array<any>, current: any) => {
      if (current.img !== null) {
        let thumb = current?.img?.formats?.thumbnail?.url;
        let large = current?.img?.url;
        let img = {
          thumb: thumb,
          large: large,
          id: current?.documentId,
          panelTypename: typename,
        };
        if (thumb !== undefined && large !== undefined) {
          result.push(img);
        }
        return result;
      } else {
        return result;
      }
    }, new Array<any>());

    // @ts-ignore
    const instance = defineComponent(TableImages);
    return [instance, { images: urls }];
  };
}
