<script setup lang="ts">
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';

interface LiveImageProps {
  img: any;
  clickable?: boolean;
  hideStateItems?: boolean;
}

const props = defineProps<LiveImageProps>();
// define emits
const emit = defineEmits<{
  (e: 'imgclicked'): void;
}>();

let img = ref(props.img);
</script>
<template>
  <div class="relative h-full">
    <div class="bg-slate-100 min-h-[8rem] w-full max-w-md relative cursor-pointer h-full" :class="[
      img?.small ? 'bg-cover bg-center' : 'skeleton-animation',
      props?.clickable ? 'cursor-pointer' : '',
      props.hideStateItems ? 'h-full' : '',
    ]" :style="img?.small ? 'background-image: url(' + img?.small + ')' : ''" @click="() => {
      emit('imgclicked');
    }
      "></div>
    <div v-if="!props.hideStateItems" class="absolute bottom-0 left-0 px-3 pb-1 m-3 rounded inline-block bg-white">
      <collapse-transition :duration="500" easing="cubic-bezier( 0, 1, 0.3, 1 )">
        <div class="pt-2 pb-1">
          <StateItem :state="props.img.status"></StateItem>
        </div>
      </collapse-transition>
    </div>
  </div>
</template>
