<script setup lang="ts">
import IconCancel from '~icons/fe/close';
import IconHide from '~icons/clarity/eye-hide-line';
import axios from 'axios';

const apiBaseUrl = import.meta.env.VITE_APIURL;

interface Image {
  id: string;
  large: string;
}
interface PanelProps {
  documentId: string;
  panel: any;
  images: Array<Image>;
  parameters?: any;
}

const props = defineProps<PanelProps>();

const emit = defineEmits(['abortRecognition', 'hidePanel']);

const visibleRef: any = ref({});
const openAutoComplete = ref(false);
const hideOptions = ref(false);

const openDirection = ref('');

const openPopup = (id: number, direction: string = "") => {
  visibleRef.value[id] = true;
  openDirection.value = direction;
};

const openPrev = (index: number) => {
  closePopup(index);
  openPopup(index - 1, 'fromLeft');
};

const openNext = (index: number) => {
  closePopup(index);
  openPopup(index + 1, 'fromRight');
};

const closePopup = (id: number) => {
  visibleRef.value[id] = false;
};

const errorOpen = ref(false);
const errorMsg = ref('');
const closeError = () => {
  errorOpen.value = false;
};

const panelModel = ref('');

const updateModel = async () => {
  try {
    const res = await axios.put(apiBaseUrl + 'solarpanels/' + props.documentId, {
      data: {
        panelmodel: panelModel.value,
        recognition_status: 'manuallyRecognized',
      },
    });
  } catch (error: any) {
    errorOpen.value = true;
    errorMsg.value = 'There has been a problem updating the panel: ' + error.response.data.error.message;
  }
};

const confirmModel = async () => {
  try {
    const res = await axios.put(apiBaseUrl + 'solarpanels/' + props.documentId, {
      data: {
        recognition_status: 'confirmed',
      },
    });
  } catch (error: any) {
    errorOpen.value = true;
    errorMsg.value = 'There has been a problem updating the panel: ' + error.response.data.error.message;
  }
};

const processParams: any = computed(() => {
  if (!props.parameters) {
    return [];
  }
  return [
    {
      knife_type: props.parameters.knife_type ? ['Knife Type', props.parameters.knife_type] : null,
      knife_height: props.parameters.knife_height ? ['Knife Height', props.parameters.knife_height] : null,
    },
    {
      start_temperature: props.parameters.start_temperature ? ['Start Temperature', props.parameters.start_temperature] : null,
      lamp_2_on_at: props.parameters.lamp_2_on_at ? ['Lamp 2 On At', props.parameters.lamp_2_on_at] : null,
      process_temperature:
        props.parameters.process_temperature_lower_limit && props.parameters.process_temperature_upper_limit
          ? [
            'Process Temperature',
            props.parameters.process_temperature_lower_limit + ' - ' + props.parameters.process_temperature_upper_limit,
          ]
          : null,
    },
    {
      brush_height_scratching: props.parameters.brush_height_scratching
        ? ['Brush Height Scratching', props.parameters.brush_height_scratching]
        : null,
      brush_height_removal: props.parameters.brush_height_removal
        ? ['Brush Height Removal', props.parameters.brush_height_removal]
        : null,
    },
  ];
});
</script>
<template>
  <div>
    <div class="flex w-full bg-white border border-slate-200 rounded relative text-sm">
      <div class="p-5 border-r border-slate-200 transition-all duration-500 min-w-[22rem] max-w-[45%] min-h-[16.5rem]"
        :class="{
          ' w-[50rem]': props.panel.recognitionKey == 'recognitionFailed' || openAutoComplete,
          ' w-[22rem]': props.panel.recognitionKey != 'recognitionFailed' && !openAutoComplete,
        }">
        <h3 class="text-2xl text-slate-300 font-medium mb-3 pb-2">
          <span class="text-slate-900 transition-transform duration-500 inline-block"><span
              class="text-slate-400">#</span>{{ props.panel.id }}</span>
          <a v-if="props.panel.recognitionKey == 'processing'"
            class="flex cursor-pointer items-center text-red-600 py-[0.3rem] pr-3 pl-2 rounded text-xs float-right font-medium leading-5 border-red-600 border hover:bg-red-50"
            @click="emit('abortRecognition', props.documentId)">
            <IconCancel class="text-base mr-1 inline-block"></IconCancel>Cancel
          </a>
          <a v-if="['manuallyRecognized', 'autoRecognized', 'recognitionFailed', 'confirmed'].includes(props.panel.recognitionKey)"
            class="flex cursor-pointer items-center text-red-600 py-[0.3rem] pr-3 pl-2 rounded text-xs float-right font-medium leading-5 border-red-600 border hover:bg-red-50"
            @click="emit('hidePanel', props.documentId)">
            <IconHide class="text-base mr-1 inline-block"></IconHide>Hide
          </a>
        </h3>
        <div class="flex gap-3 items-center mb-3">
          <p class="inline-block py-1 px-3 text-xs text-white rounded-2xl" :class="props.panel.recognitionColor">
            {{ props.panel.recognitionState }}
          </p>
        </div>
        <div v-if="props.panel.recognitionKey != 'recognitionFailed'"
          class="flex flex-wrap gap-x-2 items-center mb-3 mt-5">
          <p class="text-sm text-slate-900 font-bold h-6 leading-6">Manufacturer:</p>
          <p class="flex items-center">
            <span class="block rounded h-6 leading-6 px-2 text-center font-normal overflow-hidden" :class="{
              'bg-slate-100': props.panel.recognitionKey != 'processing' && props.panel.manufacturer,
              'skeleton-animation w-36': props.panel.recognitionKey == 'processing',
            }">
              {{ props.panel.manufacturer ?? '' }}</span>
          </p>
        </div>
        <div v-if="props.panel.recognitionKey != 'recognitionFailed'" class="flex flex-wrap gap-x-2 items-center">
          <p class="text-sm text-slate-900 font-bold h-6 leading-6">Model:</p>
          <p class="flex items-center">
            <span class="block rounded h-6 leading-6 px-2 text-center font-normal overflow-hidden" :class="{
              'bg-slate-100': props.panel.recognitionKey != 'processing' && props.panel.model,
              'skeleton-animation w-36': props.panel.recognitionKey == 'processing',
            }">
              {{ props.panel.model ?? '' }}</span>
          </p>
        </div>
        <div v-if="props.panel.openOptions && !hideOptions" class="mt-5">
          <div class="inline-block mr-3 w-36">
            <a class="button" @click="confirmModel()">Confirm</a>
          </div>
          <div class="inline-block w-36">
            <a class="button button-outline" @click="() => { openAutoComplete = true; hideOptions = true }">Change</a>
          </div>
        </div>
        <form @submit.prevent="updateModel()"
          v-if="openAutoComplete || props.panel.recognitionKey == 'recognitionFailed'" class="mt-5">
          <p v-if="props.panel.recognitionKey == 'recognitionFailed'"
            class="text-sm text-slate-900 font-medium h-6 leading-6">Search for panel type:</p>
          <div class="flex gap-2">
            <ModelAutoComplete v-model="panelModel"></ModelAutoComplete>
            <button type="submit" class="button">Save</button>
            <a v-if="props.panel.openOptions" class="button button-outline"
              @click="() => { openAutoComplete = false; hideOptions = false }">
              <IconCancel class="text-base mr-1 inline-block"></IconCancel>
            </a>
          </div>
        </form>
      </div>
      <div v-if="props.parameters && ['manuallyRecognized', 'confirmed'].includes(props.panel.recognitionKey)"
        class="p-5 relative border-r border-slate-200 last:border-none grow">
        <div class="flex flex-wrap items-center gap-y-4 w-full h-full">
          <template v-for="(item, index) in processParams" :key="index">
            <div v-if="!Object.values(item).every(e => e === null)" class="flex shrink-0 basis-full">
              <div
                class="text-base -ml-[1px] border border-slate-200 text-slate-900 first:ml-0 first:rounded-l last:rounded-r overflow-hidden"
                v-for="(child, index) in item" :key="index">
                <span v-if="child" class="block pr-10 pl-2 border-b bg-slate-100 border-slate-100 text-sm">{{ child[0]
                  }}:</span>
                <span v-if="child" class="font-semibold pl-2 pr-10 text-lg block">{{ child[1] }}</span>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div v-else-if="['manuallyRecognized', 'confirmed'].includes(props.panel.recognitionKey)"
        class="flex items-center justify-center grow text-lg text-slate-500">
        This panel model currently has no process parameters assigned.
      </div>
      <div
        v-else-if="Object.keys(props.images).length > 0 && ['recognitionFailed', 'processing', 'autoRecognized'].includes(props.panel.recognitionKey)"
        class="p-5 relative grow flex items-start">
        <div v-for="(img, index) of props.images" :key="index" class="relative h-full max-w-md grow mr-5 last:mr-0">
          <LiveImage class="border border-white" @imgclicked="openPopup(index)" :img="img">
          </LiveImage>
          <ImagePopup v-if="visibleRef[index]" :imgurl="img.large" :imgid="img.id" @close="closePopup(index)"
            :openDirection="openDirection" :hasPrev="index != 0" :hasNext="index < Object.keys(props.images).length - 1"
            @prev="openPrev(index)" @next="openNext(index)">
          </ImagePopup>
        </div>
      </div>
      <div v-else class="flex items-center justify-center grow text-lg text-slate-500">This panel currently has no
        images
        assigned.</div>
    </div>
    <Teleport to="body">
      <DialogPopup :open="errorOpen" @close="closeError()" :appearance="'error'" :title="'Error'">
        {{ errorMsg }}
      </DialogPopup>
    </Teleport>

  </div>
</template>
