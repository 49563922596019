<script setup lang="ts">
import IconArrow from '~icons/clarity/angle-line';
import { useSocketStore } from '@/store';
import { onMounted, onBeforeMount, onUnmounted } from 'vue';
import io from 'socket.io-client';
import axios from 'axios';
import qs from 'qs';
import RecognitionStateFormatter from '@/services/RecognitionStateFormatter';

const emit = defineEmits(['dispatchedEntry', 'newEntry', 'recognitionChange']);

const apiBaseUrl = import.meta.env.VITE_APIURL;
const apiServerUrl = import.meta.env.VITE_APISERVERURL;

// get socket from pinia store or create it if not existing
const socketStore: any = useSocketStore();
let socket: any = null;

onBeforeMount(() => {
  socket = (() => {
    if (!socketStore.socket) {
      const socket = io(apiServerUrl, {
        auth: {
          token: sessionStorage?.getItem('jwt'),
        },
      });
      socketStore.$patch({
        socket: socket,
      });
      return socket;
    } else {
      return socketStore.socket.connect();
    }
  })();
});

let panels: any = ref({});
let images: any = ref({});
let expandPanels: any = ref(true);

interface ImageList {
  [key: number]: any;
}

const addToImageList = (data: any, type: string) => {
  const arr = data.recognition_images;

  let imgs: {} = arr.reduce((result: ImageList, current: any) => {
    if (current?.img) {
      let small_img = current?.img?.formats?.small?.url;
      let large_img = current?.img?.url;

      result[current?.documentId] = {
        small: small_img,
        large: large_img,
        status: (current?.recognition_status == "success" && current?.is_basis_for) ? "bestmatch" : current?.recognition_status,
      };
    }
    return result;
  }, reactive({}));

  images.value = { ...images.value, ...imgs };
};

// Get all panels with recognitionStatus "processing" and store them in the panels variable
const getPanels = async () => {
  // Create axios configuration to select only processing panels and populate the images
  const requestParams = {
    params: {
      populate: {
        recognition_images: {
          populate: {
            img: {
              populate: true,
            },
            is_basis_for: true,
          },
        },
        panelmodel: {
          populate: true,
        },
      },
      filters: {
        recognition_status: {
          $eq: 'processing',
        },
      },
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params);
    },
  };

  let response = await axios.get(apiBaseUrl + 'solarpanels', requestParams);
  if (response.status === 200) {
    for (let i = 0; i < response.data.data.length; i++) {
      let imageIds = null;
      if (response?.data?.data[i]?.recognition_images?.length > 0) {
        imageIds = response?.data?.data[i]?.recognition_images.map((img: any) => img.documentId);
      }
      panels.value[response.data.data[i].documentId] = {
        id: response.data.data[i].id,
        recognitionKey: 'processing',
        recognitionColor: RecognitionStateFormatter.getColor('processing'),
        recognitionState: RecognitionStateFormatter.getLabel('processing'),
        manufacturer: response.data.data[i].panelmodel?.manufacturer,
        model: response.data.data[i].panelmodel?.typename,
        modelId: response.data.data[i].panelmodel?.documentId,
        imageIds: imageIds,
      };
      addToImageList(response.data.data[i], 'http');
    }
  }
};

await getPanels();

onMounted(() => {
  socket.on('solarpanel:newSolarPanel', (data: { data: { documentId: string; recognition_status: string; recognition_images: any } }) => {
    newSolarPanel(data.data);
  });
  socket.on(
    'solarpanel:recognitionChange',
    (data: { data: { documentId: string; recognition_status: string; manufacturer: string; typename: string; modelId: string, model_based_on: string } }) => {
      recognitionChange(data.data);
    }
  );
  socket.on('recognition-image:recognitionChange', (data: { data: { documentId: string; recognition_status: string } }) => {
    imageRecognitionChange(data.data);
  });
});

onUnmounted(() => {
  socket.off('solarpanel:newSolarPanel');
  socket.off('solarpanel:recognitionChange');
  socket.off('recognition-image:recognitionChange');
});

const newSolarPanel = (data: any) => {
  emit('newEntry');

  const documentId = data?.documentId;
  const recognitionState = data?.recognition_status;

  panels.value[documentId] = {
    id: panels.value[documentId]?.id ?? data?.id,
    recognitionKey: panels.value[documentId]?.recognitionKey ?? recognitionState,
    recognitionColor: panels.value[documentId]?.recognitionColor ?? RecognitionStateFormatter.getColor(recognitionState),
    recognitionState: panels.value[documentId]?.recognitionState ?? RecognitionStateFormatter.getLabel(recognitionState),
    manufacturer: panels.value[documentId]?.manufacturer ?? '',
    model: panels.value[documentId]?.model ?? '',
    modelId: panels.value[documentId]?.modelId ?? '',
    imageIds: data?.recognition_images?.map((image: any) => {
      return image.documentId;
    }, new Array<number>()),
  };
  addToImageList(data, 'socket');
};

const recognitionChange = (data: { documentId: string, recognition_status: string, manufacturer: string, typename: string, modelId: string, model_based_on: string }) => {

  const documentId = data.documentId;
  const recognition_state = data.recognition_status;
  const manufacturer = data.manufacturer;
  const typename = data.typename;
  const modelId = data.modelId;
  const model_based_on = data.model_based_on;

  if (!panels.value[documentId]) {
    return;
  }
  panels.value[documentId].recognitionKey = recognition_state;
  panels.value[documentId].recognitionState = RecognitionStateFormatter.getLabel(recognition_state);
  panels.value[documentId].recognitionColor = RecognitionStateFormatter.getColor(recognition_state);
  panels.value[documentId].manufacturer = manufacturer;
  panels.value[documentId].model = typename;
  panels.value[documentId].modelId = modelId;

  if (recognition_state == 'recognitionFailed' || recognition_state == 'manuallyRecognized' || recognition_state == 'autoRecognized' || recognition_state == 'confirmed' || recognition_state == 'processing') {
    emit('recognitionChange');

    if (!panels.value[documentId]) {
      return;
    }

    if (recognition_state == 'autoRecognized') {
      triggerShowOptions(documentId, true);
    }
    if (recognition_state == 'manuallyRecognized' || recognition_state == 'confirmed') {
      triggerShowParameters(documentId);
      triggerShowOptions(documentId, false);
    }

    if (recognition_state == 'confirmed' || recognition_state == 'autoRecognized') {
      setToTypenameSource(model_based_on);
    }

  }
};

const triggerShowOptions = (documentId: string, val: boolean) => {
  panels.value[documentId].openOptions = val;
}

const triggerShowParameters = (documentId: string) => {
  // request parameters using axios
  const requestParams = {
    params: {
      populate: 'process_parameter',
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params);
    },
  };

  axios
    .get(apiBaseUrl + 'panelmodels/' + panels.value[documentId].modelId, requestParams)
    .then(response => {
      if (response.status === 200) {
        panels.value[documentId].parameters = response?.data?.data?.process_parameter;
      }
    })
    .catch(error => {
      console.log(error);
    });
};

const imageRecognitionChange = (data: { documentId: string; recognition_status: string }) => {
  const img_id = data.documentId;
  const status = data.recognition_status;

  if (!images.value[img_id]) {
    return;
  }
  images.value[img_id].status = status;
};

const setToTypenameSource = (img_id: string) => {
  for (const [key, value] of Object.entries(images.value)) {
    if (key == img_id) {
      images.value[key].status = "bestmatch";
    } else if (images.value[key].status == "bestmatch") {
      images.value[key].status = "success";
    }
  }
};

const panelsImages = computed(() => {
  // For every panel, store the according images in an array
  let list: any = {};
  for (const panelid in panels.value) {
    let imglist: Array<any> = [];
    if (panels.value[panelid]?.imageIds?.length > 0) {
      for (const img of panels.value[panelid]?.imageIds) {
        if (images.value[img]) {
          imglist.push({
            id: img,
            ...images.value[img],
          });
        }
      }
    }
    if (Object.keys(imglist).length > 0) {
      list[panelid] = imglist;
    } else {
      list[panelid] = {};
    }
  }
  return list;
});

// set recognitionState of panel to "failed"
const abortRecognition = async (panelId: string) => {
  if (panels.value[panelId].recognitionState == 'recognitionFailed') {
    removePanel(panelId);
  } else {
    const requestParams = {
      data: {
        recognition_status: 'recognitionFailed',
      },
    };
    await axios.put(apiBaseUrl + 'solarpanels/' + panelId, requestParams);
    if (panels.value[panelId]) {
      removePanel(panelId);
    }
  }
};

const removePanel = (documentId: string) => {
  delete panels.value[documentId];
  emit('dispatchedEntry');
};

const tooltiptext = computed(() => {
  if (!expandPanels) {
    return 'Collapse panel list';
  } else {
    return 'Expand panel list';
  }
});
</script>
<template>
  <div class="relative pb-2 mb-10 min-h-[21rem] z-20 h-auto interpolate-size">
    <div>
      <h3
        class="mb-3 text-lg leading-8 inline-flex items-center justify-center font-medium text-slate-900 cursor-pointer"
        @click="expandPanels = !expandPanels">
        <div v-if="Object.keys(panels).length > 1" class="h-8 -ml-1 mr-1">
          <Tooltip :content="tooltiptext">
            <div class="transition-transform text-slate-900 w-8 h-8 inline-flex items-center justify-center"
              :class="{ 'rotate-180': expandPanels, 'rotate-90': !expandPanels }">
              <IconArrow class="w-6 h-6"></IconArrow>
            </div>
          </Tooltip>
        </div>
        Currently processing:
      </h3>
      <Transition name="slidein">
        <h4 v-if="Object.keys(panels).length == 0"
          class="mb-2 text-base font-normal text-slate-600 absolute text-center py-20 rounded border-dashed border-2 border-slate-200 w-full">
          No panels are being processed currently.
        </h4>
      </Transition>
    </div>

    <div>
      <TransitionGroup name="slidein">
        <LiveWatchPanel
          v-for="(documentId, index) in Object.keys(panels).sort((a: any, b: any) => panels[a].id - panels[b].id).reverse()"
          :key="documentId"
          class="scale-100 transition-all origin-bottom duration-[0.15s] w-full mb-2 bottom-0 first:z-30 focus-within:z-40"
          :class="{
            'absolute transition-all scale-95 origin-bottom -bottom-3 z-20': !expandPanels && index == 1,
            'absolute transition-all scale-90 origin-bottom -bottom-6 z-10': !expandPanels && index == 2,
            'absolute transition-all scale-90 origin-bottom -bottom-6 z-0 opacity-0': !expandPanels && index > 2,
            'transition-all relative opacity-100 mb-2 z-30': expandPanels || index == 0,
          }" :documentId="documentId" :panel="panels[documentId]" :images="panelsImages[documentId]"
          @abortRecognition="abortRecognition(documentId)" @hidePanel="removePanel(documentId)"
          :parameters="panels[documentId].parameters">
        </LiveWatchPanel>
      </TransitionGroup>
    </div>
  </div>
</template>
<style scoped>
.interpolate-size {
  interpolate-size: allow-keywords;
}

.transition-height {
  transition: height 0.5 ease;
}
</style>